<template>
  <div style="background-color: rgba(241, 245, 254, 1); padding-bottom: 110px">
    <!-- dialog -->

    <van-dialog
      v-model="exchangeModal"
      title="红利转余额"
      show-cancel-button
      :before-close="confirmExchange"
      class="exchangeModal"
    >
      <template #title>
        <div>
          <p style="text-align: left; padding-left: 5px">红利转余额</p>
          <img src="/new/lucky money 1.png" alt="" class="luckyI" />
        </div>
      </template>
      <div>
        <p class="exchangeInfo" style="text-align: center">
          可转红利{{ selfData.bonus }}
        </p>
      </div>
      <van-field
        v-model="amount"
        name="金额"
        label="金额"
        type="number"
        placeholder="金额"
        input-align="right"
        :rules="[{ required: true, message: '请填写金额' }]"
      />
    </van-dialog>
    <van-dialog
      v-model="show"
      :title="'自动' + $returnBuySel('进货')"
      show-cancel-button
      :before-close="changeDsBefore"
    >
      <p style="text-align: center; font-size: 14px; color: red">
        金额可设置区间 {{ selfData.df_min }} ~ {{ selfData.df_max }}
      </p>
      <van-field name="switch" label="自动">
        <template #input>
          <van-switch
            v-model="on_duty_df"
            active-color="#009aaa"
            inactive-color="#cccccc"
            size="20"
          />
        </template>
      </van-field>
      <van-field
        v-if="selfData.alter_self_df_min == 1"
        v-model="selfData.self_df_min"
        name="最低"
        label="最低"
        placeholder="最低"
        type="number"
        :rules="[{ required: true, message: '请填写最低' }]"
      />
      <van-field
        v-else
        disabled
        v-model="selfData.df_min"
        name="最低"
        label="最低"
        placeholder="最低"
        type="number"
        :rules="[{ required: true, message: '请填写最低' }]"
      />
      <van-field
        v-model="selfData.self_df_max"
        name="最高"
        label="最高"
        type="number"
        placeholder="最高"
        :rules="[{ required: true, message: '请填写最高' }]"
      />
    </van-dialog>
    <van-dialog v-model="checkModal" :title="'出货检查'" class="checkModal">
      <div v-if="checkData" class="checkBody">
        <van-cell-group inset>
          <van-cell is-link @click="showMsg(checkData.on_duty_ds.note)">
            <template #title>
              <span class="custom-title">
                <van-icon
                  class="success"
                  v-if="checkData.on_duty_ds.check"
                  name="checked"
                />
                <van-icon class="err" v-else name="clear" />
                出货开关</span
              >
            </template>
            <template #right-icon>
              <p v-if="checkData.on_duty_ds.note" style="color: red">查看</p>
            </template>
          </van-cell>
        </van-cell-group>
        <van-cell-group inset>
          <van-cell is-link @click="showMsg(checkData.can_collection.note)">
            <template #title>
              <span class="custom-title">
                <van-icon
                  class="success"
                  v-if="checkData.can_collection.check"
                  name="checked"
                />
                <van-icon class="err" v-else name="clear" />
                出货权限</span
              >
            </template>
            <template #right-icon>
              <p v-if="checkData.can_collection.note" style="color: red">
                查看
              </p>
            </template>
          </van-cell>
        </van-cell-group>
        <van-cell-group inset>
          <van-cell is-link @click="showMsg(checkData.balance.note)">
            <template #title>
              <span class="custom-title">
                <van-icon
                  class="success"
                  v-if="checkData.balance.check"
                  name="checked"
                />
                <van-icon class="err" v-else name="clear" />
                余额</span
              >
            </template>
            <template #right-icon>
              <p v-if="checkData.balance.note" style="color: red">查看</p>
            </template>
          </van-cell>
        </van-cell-group>
        <van-cell-group inset>
          <van-cell
            is-link
            @click="showMsg(card.note)"
            v-for="(card, key) in checkData.cards"
            :key="key"
          >
            <template #title>
              <span class="custom-title">
                <van-icon class="success" v-if="card.check" name="checked" />
                <van-icon class="err" v-else name="clear" />
                {{ card.bank }}</span
              >
            </template>
            <template #right-icon>
              <p v-if="card.note" style="color: red">查看</p>
            </template>
          </van-cell>
        </van-cell-group>
      </div>
    </van-dialog>
    <van-dialog
      class="FAModal"
      :before-close="bindBefore"
      v-model="FABind"
      title="绑定谷歌验证码"
      show-cancel-button
    >
      <div style="width: 100%; display: flex; justify-content: center">
        <vue-qrcode
          :value="
            encodeURI(`otpauth://totp/${selfData.username}?secret=${secret}`)
          "
        />
      </div>
      <van-form @submit="bindFA" ref="oneCodeForm">
        <van-field label="密钥" :value="secret" readonly />
        <van-button
          v-clipboard:copy="secret"
          v-clipboard:success="onCopy"
          type="primary"
          block
          >复制</van-button
        >
        <van-field
          v-model="oneCode"
          name="验证码"
          label="验证码"
          placeholder="验证码"
          :rules="[{ required: true, message: '请填写验证码' }]"
        />
      </van-form>
    </van-dialog>
    <van-dialog
      :before-close="setDfBefore"
      v-model="setDfModal"
      title="设定自动接单"
      show-cancel-button
    >
      <van-form>
        <van-field
          v-model="self.self_df_min"
          name="最低"
          label="最低"
          placeholder="最低"
          type="number"
          :rules="[{ required: true, message: '请填写最低' }]"
        />
        <van-field
          v-model="self.self_df_max"
          name="最高"
          label="最高"
          type="number"
          placeholder="最高"
          :rules="[{ required: true, message: '请填写最高' }]"
        />
      </van-form>
    </van-dialog>
    <van-dialog
      :before-close="editPasswordBefore"
      v-model="editPasswordModal"
      title="变更密码"
      show-cancel-button
    >
      <van-form>
        <van-field
          v-model="password"
          name="旧密码"
          label="旧密码"
          placeholder="输入旧密码"
          required
          input-align="right"
          :rules="[{ required: true, message: '请填写旧密码' }]"
        />
        <van-field
          v-model="newPassword"
          name="密码"
          label="密码"
          required
          input-align="right"
          placeholder="输入新密码"
          :rules="[{ required: true, message: '请填写密码' }]"
        />
      </van-form>
    </van-dialog>
    <van-dialog
      :before-close="editWithDrawPasswordBefore"
      v-model="editWithDrawPasswordModal"
      title="变更提现密码"
      show-cancel-button
    >
      <van-form>
        <van-field
          v-model="password"
          name="旧密码"
          label="旧密码"
          placeholder="输入旧密码"
          input-align="right"
          required
          :rules="[{ required: true, message: '请填写旧密码' }]"
        />
        <van-field
          v-model="newPassword"
          name="密码"
          label="密码"
          placeholder="输入新密码"
          input-align="right"
          required
          :rules="[{ required: true, message: '请填写密码' }]"
        />
      </van-form>
    </van-dialog>
    <!-- dialog -->
    <!-- top -->
    <!-- <van-sticky>
      <van-nav-bar title="个人信息"> </van-nav-bar>
    </van-sticky> -->
    <!-- top -->
    <div class="wrap">
      <div
        class="title"
        :style="{
          backgroundImage:
            'url(' +
            $returnColor().path +
            ($store.state.selfData.member_id
              ? '/new/selfBg.png'
              : '/new/selfBg.png') +
            ')',
        }"
      >
        <div class="head">
          <img
            style="width: 70px"
            src="../../assets/images/login_icon.png"
            alt=""
            class="userTitle"
          />
        </div>
        <div class="info">
          <p class="name">{{ selfData.name }}</p>
          <p class="memberId">{{ selfData.member_id }}</p>
        </div>
        <img class="rwa" src="/new/rwa.svg" alt="" />
      </div>
      <div class="amountWrap">
        <div class="top">
          <p class="label">余额(元)</p>
          <p class="amount">{{ selfData.balance | thousands }}</p>
        </div>
        <div class="bottom">
          <div class="left">
            <div class="img">
              <img src="/new/bai.png" alt="" />
            </div>
            <div>
              <p>红利(元)</p>
              <p class="numF">{{ selfData.bonus | thousands }}</p>
            </div>
          </div>
          <div class="right">
            <div class="img"><img src="/new/boni.png" alt="" /></div>
            <div>
              <p>进行中(元)</p>
              <p class="numF">{{ selfData.processing_amount | thousands }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 130px">
      <div class="switchWrap">
        <div class="left">
          <div class="title">{{ $returnBuySel("进货") }}</div>
          <div class="set">
            <van-switch
              style="margin-top: 5px"
              :value="selfData.on_duty_df == 1"
              @input="changeDf"
              size="20"
              active-color="rgba(64, 101, 240, 1)"
              inactive-color="#cccccc"
            />
          </div>
          <p class="numF">
            ¥<span v-if="selfData.alter_self_df_min == 1">
              {{ selfData.self_df_min | thousands }}
            </span>
            <span v-else> {{ selfData.df_min | thousands }} </span>-<span>
              {{ selfData.self_df_max | thousands }}
            </span>
          </p>
        </div>
        <div class="right">
          <div class="title">{{ $returnBuySel("出货") }}</div>
          <div class="set">
            <van-switch
              style="margin-top: 5px"
              :value="selfData.on_duty_ds == 1"
              size="20"
              @input="changeDs"
              active-color="rgba(64, 101, 240, 1)"
              inactive-color="#cccccc"
            />
          </div>
          <p @click="showCheck" class="selfCheck">没有跟进单吗？小帮手检查</p>
        </div>
      </div>
    </div>
    <div class="celWrap">
      <label>我的团队</label>
      <div class="seleWrap">
        <div class="sele" @click="$router.push('/myReport')">
          <img src="/new/report.png" alt="" />
          <p>个人报表</p>
        </div>
        <div class="sele" @click="$router.push('/teamReport')">
          <img src="/new/team.png" alt="" />
          <p>团队报表</p>
        </div>
        <div class="sele" @click="$router.push('/subList')">
          <img src="/new/steam.png" alt="" />
          <p>下线列表</p>
        </div>
        <!-- <div class="sele" @click="$router.push('/createAcc')">
          <img src="/new/create.png" alt="" />
          <p>创建下线</p>
        </div> -->
      </div>
    </div>
    <div class="celWrap">
      <label>我的团队</label>
      <div class="seleWrap">
        <div class="sele" @click="$router.push('/bankCardP')">
          <img src="/new/card.png" alt="" />
          <p>收付款账户</p>
        </div>
        <div
          class="sele"
          @click="
            editPasswordModal = true;
            newPassword = '';
            password = '';
          "
        >
          <img src="/new/p.png" alt="" />
          <p>修改密码</p>
        </div>
        <div
          class="sele"
          @click="
            editWithDrawPasswordModal = true;
            newPassword = '';
            password = '';
          "
        >
          <img src="/new/ep.png" alt="" />
          <p>修改提现密码</p>
        </div>
        <div class="sele" @click="set2FA">
          <img src="/new/lock.png" alt="" />
          <p>谷歌验证</p>
        </div>
      </div>
      <div class="seleWrap">
        <div class="sele" @click="$router.push('invites')">
          <img src="/new/code.png" alt="" />
          <p>邀请码</p>
        </div>
        <div
          class="sele"
          @click="
            amount = '';
            exchangeModal = true;
          "
        >
          <img src="/new/btb.png" alt="" />
          <p>红利转余额</p>
        </div>

        <div class="sele" @click="$router.push('orderCecordSend')">
          <img src="/new/sf.png" alt="" />
          <p>传送资料</p>
        </div>
      </div>
    </div>
    <div class="logout" @click="logout">
      <img src="/new/logout.png" alt="" />登出
    </div>
    <!-- <van-cell style="margin-top: 15px">
      <template #title>
        <span class="custom-title"><van-icon name="user-o" />会员设定</span>
      </template>
    </van-cell>
    <van-cell is-link to="/bankCardP">
      <template #title>
        <span class="custom-title">收付款账户</span>
      </template>
    </van-cell>
    <van-cell
      is-link
      @click="
        editPasswordModal = true;
        newPassword = '';
        password = '';
      "
    >
      <template #title>
        <span class="custom-title">修改密码</span>
      </template>
    </van-cell>
    <van-cell
      is-link
      @click="
        editWithDrawPasswordModal = true;
        newPassword = '';
        password = '';
      "
    >
      <template #title>
        <span class="custom-title">修改提现密码</span>
      </template>
    </van-cell>
    <van-cell is-link @click="set2FA">
      <template #title>
        <span class="custom-title">谷歌验证</span>
      </template>
    </van-cell>
    <van-cell is-link @click="$router.push('invites')">
      <template #title>
        <span class="custom-title">邀请码</span>
      </template>
    </van-cell>
    <p class="divider">我的团队</p>
    <van-cell is-link to="/myReport">
      <template #title>
        <span class="custom-title">个人{{ $returnBuySel("报表") }}</span>
      </template>
    </van-cell>
    <van-cell is-link to="/teamReport">
      <template #title>
        <span class="custom-title">团队{{ $returnBuySel("报表") }}</span>
      </template>
    </van-cell>
    <van-cell is-link to="/subList" v-if="!selfData.hide_team">
      <template #title>
        <span class="custom-title">下线列表</span>
      </template>
    </van-cell>
    <van-cell is-link to="/createAcc">
      <template #title>
        <span class="custom-title">创建下线</span>
      </template>
    </van-cell>
    <van-cell is-link @click="logout">
      <template #title>
        <span class="custom-title">登出</span>
      </template>
    </van-cell> -->
    <foot />
  </div>
</template>
<script>
import { mapState } from "vuex";
import foot from "../../components/footer";
import { Toast } from "vant";

import VueQrcode from "vue-qrcode";
export default {
  components: { foot, VueQrcode },
  data() {
    return {
      editPasswordModal: false,
      editWithDrawPasswordModal: false,
      newPassword: "",
      password: "",
      setDfModal: false,
      self: {},
      FABind: false,
      secret: "",
      oneCode: "",
      checkData: false,
      checkModal: false,
      on_duty_df: false,
      show: false,
      exchangeModal: false,
      amount: "",
    };
  },
  computed: {
    ...mapState([
      "selfData",
      // ...
    ]),
  },
  methods: {
    confirmExchange(method, done) {
      if (method == "confirm") {
        this.$http
          .post(`/mobile/exchange`, {
            amount: this.amount,
          })
          .then((res) => {
            if (res.data.code == 0) {
              this.$store.dispatch("getSelf");
              done();
            } else {
              done(false);
            }
          });
      } else {
        done();
      }
    },
    changeDs(checked) {
      this.$dialog
        .confirm({
          title: "提醒",
          message: "是否切自动" + this.$returnBuySel("出货") + "?",
        })
        .then(() => {
          this.$http
            .post(`/mobile/personalSetting`, {
              on_duty_ds: checked,
            })
            .then((res) => {
              if (res.data.code == 0) {
                this.$store.commit("setSelf", res.data.data);
              }
            });
        });
    },
    changeDsBefore(method, done) {
      if (method == "confirm") {
        if (this.selfData.alter_self_df_min == 1) {
          if (this.selfData.self_df_min * 1 < this.selfData.df_min * 1) {
            this.$dialog.confirm({
              title: "提醒",
              message: "请输入有效范围",
              showCancelButton: false,
            });
            done(false);
            return;
          }
          if (this.selfData.self_df_max * 1 < this.selfData.self_df_min * 1) {
            this.$dialog.confirm({
              title: "提醒",
              message: "请输入有效范围",
              showCancelButton: false,
            });
            done(false);
            return;
          }
        }
        if (this.selfData.self_df_max * 1 > this.selfData.df_max * 1) {
          this.$dialog.confirm({
            title: "提醒",
            message: "请输入有效范围",
            showCancelButton: false,
          });
          done(false);
          return;
        }
        if (this.selfData.self_df_max * 1 > this.selfData.df_max * 1) {
          this.$dialog.confirm({
            title: "提醒",
            message: "请输入有效范围",
            showCancelButton: false,
          });
          done(false);
          return;
        }
        let sendData = {
          on_duty_df: this.on_duty_df ? 1 : 0,
          self_df_min: this.selfData.self_df_min,
          self_df_max: this.selfData.self_df_max,
        };
        if (!this.selfData.alter_self_df_min == 1) {
          delete sendData.self_df_min;
        }
        this.$http.post(`/mobile/personalSetting`, sendData).then((res) => {
          if (res.data.code == 0) {
            this.$store.commit("setSelf", res.data.data);
            // this.$store.dispatch("getSelf");
            done();
          } else {
            done(false);
          }
        });
      } else {
        done();
      }
    },
    changeDf(checked) {
      this.on_duty_df = !checked;
      this.show = true;
    },
    showMsg(msg) {
      if (!msg) return;
      this.$dialog.alert({
        message: msg,
      });
    },
    showCheck() {
      this.$http.get(`/mobile/selfCheck`).then((res) => {
        if (res.data.code == 0) {
          this.checkData = res.data.data;
          this.checkModal = true;
        }
      });
    },
    bindBefore(method, done) {
      if (method == "confirm") {
        this.$refs.oneCodeForm.submit();
        done(false);
      } else {
        this.FABind = false;
        done();
        return;
      }
    },
    bindFA() {
      this.$http
        .post(`mobile/2fa`, {
          oneCode: this.oneCode,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$toast("成功");
            setTimeout(() => {
              this.$store.dispatch("getSelf");
            }, 1000);
            this.FABind = false;
          }
        });
    },
    onCopy() {
      this.$toast("复制成功");
    },
    set2FA() {
      if (this.selfData.enable_2fa == 0) {
        this.$http.get(`/mobile/2fa`).then((res) => {
          if (res.data.code == 0) {
            this.secret = res.data.data.secret;
            this.FABind = true;
          }
        });
      } else {
        this.$toast.success(`您已绑定`);
      }
    },
    logout() {
      this.$dialog
        .confirm({
          title: "登出",
          message: "是否登出",
        })
        .then(() => {
          this.$http.get(`/mobile/logout`).then((res) => {
            if (res.data.code == 0) {
              // if (window.Echo) {
              //   window.Echo.leave(
              //     `App.User.${this.$store.state.selfData.member_id}`
              //   );
              // }
              this.$router.push("/userLogin");
            }
          });
        });
    },
    setDfBefore(method, done) {
      if (method == "confirm") {
        this.$http
          .post(`/mobile/personalSetting `, {
            self_df_max: this.self.self_df_max,
            self_df_min: this.self.self_df_min,
          })
          .then((res) => {
            if (res.data.code == 0) {
              this.setDfModal = false;

              this.$store.commit("setSelf", res.data.data);
              // this.$store.dispatch("getSelf");
              Toast.success("成功");
            }
          });
        done(false);
      } else {
        this.editPasswordModal = false;
        done();
        return;
      }
    },
    setDf() {
      this.setDfModal = true;
      this.self = JSON.parse(JSON.stringify(this.selfData));
    },
    editPasswordBefore(method, done) {
      if (method == "confirm") {
        this.$http
          .put(`/mobile/password `, {
            new_password: this.newPassword,
            old_password: this.password,
          })
          .then((res) => {
            if (res.data.code == 0) {
              this.editPasswordModal = false;
              this.$successMeg();
            }
          });
        done(false);
      } else {
        this.editPasswordModal = false;
        done();
        return;
      }
    },
    editWithDrawPasswordBefore(method, done) {
      if (method == "confirm") {
        this.$http
          .put(`/mobile/password `, {
            new_withdraw_password: this.newPassword,
            old_withdraw_password: this.password,
          })
          .then((res) => {
            if (res.data.code == 0) {
              this.$successMeg();
              this.editWithDrawPasswordModal = false;
            }
          });
        done(false);
      } else {
        this.FABind = false;
        done();
        return;
      }
    },
  },
  created() {
    // this.$store.dispatch("getSelf");
    // this.$http.get(`/mobile/chat`);
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  padding: 0px;
  background-color: rgba(241, 245, 254, 1);
}
.cardWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .cardBox {
    width: 150px;
    height: 120px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }
  b {
    width: 140px;
    height: 0;
  }
}
.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    background-image: url("/new/selfBg.png");
    background-position: center center, center center;
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    display: flex;
    padding: 0 30px;
    width: 100%;
    padding-top: 30px;
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 20px;
    height: 276px;
    position: relative;
    .rwa {
      position: absolute;
      width: 200px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 102px;
      width: 100%;
      background: linear-gradient(
        180deg,
        rgba(241, 245, 254, 0) 0%,
        #f1f5fe 100%
      );
    }
    .head {
      display: flex;
      align-items: center;
      .img {
        width: 60px;
        height: 60px;
        background-color: #fff;
        border-radius: 100px;
      }
    }
    .info {
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 999;
      .name {
        font-size: 18px;
        font-weight: bold;
        color: white;
      }
      .memberId {
        font-size: 14px;
        font-weight: bold;
        color: white;
      }
      .amount {
        font-size: 14px;
        color: white;
        font-family: Arial;
      }
    }
  }
}
.divider {
  width: 100%;
  text-align: center;
  color: #cccccc;
  line-height: 40px;
}

.wrap {
  position: relative;
}

.amountWrap {
  background-color: #fff;
  border-radius: 15px;
  width: 90%;
  position: absolute;
  bottom: -115px;
  padding: 15px;
  .top {
    padding-left: 15px;
    .label {
      color: rgba(51, 51, 51, 1);
      font-size: 16px;
    }
    .amount {
      font-size: 24px;
      color: rgba(63, 187, 254, 1);
      font-weight: bold;
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    .left,
    .right {
      width: 48%;
      background-color: white;
      border-radius: 15px;
      display: flex;
      align-items: center;
      color: white;
      height: 65px;
      padding-left: 15px;
      font-family: Noto Sans SC;
      .numF {
        font-weight: 700;
        font-family: Arial, Helvetica, sans-serif;
      }
    }
    .left {
      background-color: #4065f0;
      .img {
        background-color: rgba(255, 255, 255, 0.2);
        width: 30px;
        height: 30px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        img {
          width: 20px;
        }
      }
    }
    .right {
      background-color: #3fbbfe;
      .img {
        background-color: rgba(255, 255, 255, 0.2);
        width: 30px;
        height: 30px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        img {
          width: 20px;
        }
      }
    }
  }
}
.switchWrap {
  width: 90%;
  background-color: white;
  margin: 0 auto;
  border-radius: 15px;
  padding: 15px;
  display: flex;
  justify-content: space-around;
  .left,
  .right {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .numF {
      font-weight: bold;
    }
    .title {
      font-weight: bold;
    }
  }
  .selfCheck {
    color: rgba(51, 51, 51, 1);
    font-size: 12px;
    font-family: PingFang SC;
  }
}

.celWrap {
  width: 90%;
  background-color: white;
  margin: 0 auto;
  border-radius: 15px;
  padding: 15px;
  margin-top: 15px;
  label {
    font-weight: bold;
    font-size: 15px;
  }
  .seleWrap {
    display: flex;
    margin-top: 15px;
    .sele {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 12px;
      width: 25%;
    }
    img {
      height: 30px;
      margin-bottom: 10px;
    }
  }
}
.logout {
  background-color: #fff;
  width: 90%;
  margin: 0 auto;
  font-size: 14px;
  color: #ff6161;
  padding: 10px 0;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  img {
    width: 15px;
    margin-right: 5px;
  }
}

.exchangeModal {
  // position: relative;
  overflow: unset !important;
  .luckyI {
    position: absolute;
    width: 135px;
    z-index: -1;
    top: -45px;
    right: 8px;
  }
  .exchangeInfo {
    color: #cf1f3a;
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
  }
}
.van-cell-group--inset {
  margin: 0 !important;
}
</style>
